import { post, deletes, get, downFile } from 'common/request'
export default {
  /**
   * 获取
   */
  // 获取常住人口列表数据
  list: params => get('/residentArchives/list', params),
  // 根据id获取任楼详情
  getResidentArchivesById: id => get(`/residentArchives/getById?id=${id}`),
  // deleteBatch: params => deletes('/operlog/deleteBatch', params),
  // exportXls: params => downFile('/operlog/exportXls', params),
  // // 获取组织机构
  // deptList: args => get('/dept/list', args),
  // // 获取组织机构 树结构
  // deptTreeList: args => get('/dept/queryTree', args),
  /**
   * 插入/修改
   */
  // 新增人口
  addPeople: args => post('/residentArchives/add', args),
  // 根据id删除
  deleteResidentArchivesById: id => deletes(`/residentArchives/deleteById?id=${id}`),
  // 批量删除
  deleteResidentArchivesByIds: ids => deletes(`/residentArchives/ids?ids=${ids}`),
  // 根据id编辑人口信息
  updateResidentArchives: args => post('/residentArchives/update', args),

  /**
     * 字典
     */
  // form里面的下拉获取数据字典
  dictData: args => get('/dict-data/getByDicKind', args),  
}
