<!-- 退役军人表格 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :toolActions="toolActions"
      :show-search="true"
      :searchForm="searchForm"
      @delect="del"
      @export="expor"
      @exportAll="exportAll"
      @add="add"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
      <div slot="age" slot-scope="{ record }" class="operName">
        {{
          new Date().getFullYear() - parseInt(record.identityCard.substr(6, 4))
        }}
      </div>
      <div slot="cellName" slot-scope="{ record }" class="operName">
        {{ record.cellName ? record.cellName : "--" }}
      </div>
      <div slot="enlistmentTime" slot-scope="{ record }" class="operName">
        {{ record.enlistmentTime ? record.enlistmentTime : "--" }}
      </div>
      <div slot="retirementTime" slot-scope="{ record }" class="operName">
        {{ record.retirementTime ? record.retirementTime : "--" }}
      </div>
      <div slot="serviceLength" slot-scope="{ record }" class="operName">
        {{ record.serviceLength ? record.serviceLength : "--" }}
      </div>
      <div slot="serviceUnit" slot-scope="{ record }" class="operName">
        {{ record.serviceUnit ? record.serviceUnit : "--" }}
      </div>
      <div slot="position" slot-scope="{ record }" class="operName">
        {{ record.position ? record.position : "--" }}
      </div>
    </ax-table>
    <!-- 新增退役军人信息弹窗 -->
    <add-people ref="specialVeteransInfo" @refsh="refsh"></add-people>
    <watch-People ref="specialVeteransInfoDetail"></watch-People>

    <!-- 走访记录弹窗 -->
    <visit-records ref="visitRecords"></visit-records>
  </div>
</template>

<script>
import AddPeople from "./addPeople.vue";
import watchPeople from "./watchPeople.vue";
import VisitRecords from "./visitRecords.vue";

//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "searchText",
    options: { placeholder: "姓名/身份证号" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所在小区",
    type: "select",
    model: "cellName",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "服役单位",
    type: "",
    model: "serviceUnit",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "服役年限",
    type: "",
    model: "serviceLength",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { AddPeople, watchPeople, VisitRecords },
  data() {
    //这里存放数据
    return {
      api,
      options: [],
      currentAreaCode: "",
      currentArea: "",
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: true,
            width: 100,
          },
          {
            title: "性别",
            dataIndex: "gender",
            ellipsis: false,
            width: 80,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 180,
          },
          {
            title: "年龄",
            dataIndex: "age",
            ellipsis: false,
            width: 60,
          },
          {
            title: "现住区域",
            dataIndex: "currentArea",
            ellipsis: true,
            width: 250,
          },
          {
            title: "所在小区",
            dataIndex: "cellName",
            ellipsis: false,
            width: 100,
          },
          {
            title: "入伍时间",
            dataIndex: "enlistmentTime",
            ellipsis: false,
            width: 140,
          },
          {
            title: "退役时间",
            dataIndex: "retirementTime",
            ellipsis: false,
            width: 140,
          },
          {
            title: "服役年限",
            dataIndex: "serviceLength",
            ellipsis: false,
            width: 80,
          },
          {
            title: "服役单位",
            dataIndex: "serviceUnit",
            ellipsis: false,
            width: 100,
          },
          {
            title: "所属职务",
            dataIndex: "position",
            ellipsis: false,
            width: 100,
          },
        ],
        false, //去掉序号
        {
          fixed: "right",
          width: 220,
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "走访",
                  name: "interview",
                  type: "#556BFF",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#556BFF",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true, //是否要弹窗
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false,
                  title: "是否删除当前内容?",
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 85,
      }),
      toolActions: [
        { name: "add", text: "新增" },
        { name: "delect", text: "批量删除", type: "#F95A5A" },
        // { name: "inports", text: "导入", type: "#67C23A" },
        { name: "export", text: "导出筛选", type: "#67C23A" },
        { name: "exportAll", text: "导出全部", type: "#67C23A" },
      ],
      selectedRowKeys: [],
      currentAreaCode1: [],
      dataSourceApis: api.veteranList,
      dataSourceParams: {},
      selectedPeopleList: [],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    async getTableListData() {
      const obj = {
        pageNo: 1,
        pageSize: 10,
      };
      const res = await api.veteranList(obj);
      this.$refs.tableBox.getDataSource(obj);
      this.selectedPeopleList = res.data.records;
      // console.log(this.selectedPeopleList, "表格已有数据");
    },
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 走访
        case "interview":
          // record.title = '编辑精神病患者信息'
          this.$refs.visitRecords.openModal(record);
          break;
        // 编辑
        case "edit":
          record.title = "编辑退役军人信息";
          this.$refs.specialVeteransInfo.openModal(record);
          break;
        // 详情
        case "watch":
          record.title = "查看退役军人信息";
          this.$refs.specialVeteransInfoDetail.openModal(record);
          break;
        // 详情
        case "del":
          break;
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      const res = await api.deleteveteranById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.$refs.tableBox.getDataSource();
      }
    },
    refsh() {
      this.getTableListData();
      this.$refs.tableBox.getDataSource();
    },
    add() {
      this.$refs.specialVeteransInfo.openModal({
        title: "新增退役军人",
        selectedPeopleList: this.selectedPeopleList,
      });
    },
    // 导出
    expor() {
      this.searchForm.formValues.currentAreaCode = this.currentAreaCode;
      const values = JSON.parse(JSON.stringify(this.searchForm.formValues));
      console.log(this.searchForm.formValues, "89");
      api.exports({
        fileName: "退役军人信息.xls",
        params: values,
      });
    },
    // 导出全部
    exportAll() {
      const values = "";
      console.log(this.searchForm.formValues, "89");
      api.exports({
        fileName: "退役军人信息.xls",
        params: values,
      });
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteveteranByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 获取字典
    async getDictionsary() {
      // 所在小区
      await api.getGridManagement().then((res) => {
        const options = res.data.map((res) => {
          return { label: res.name, value: res.name };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "cellName",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getTableListData();
    this.getDictionsary();
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
// 解决固定列时出现空列问题
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
  border-left: none;
}
</style>