<!--  -->
<template>
  <div class=''>
    <a-row :gutter="16" style="height: 100%;">
      <a-col class="gutter-row" :span="4" style="height: 100%;" >
        <silder-menu-list @changeTableName="changeTableName" :menuListData="menuListData" class="cursor"
          :searchTitle="searchTitle"></silder-menu-list>
      </a-col>
      <a-col class="gutter-row" :span="20" style="height: 100%;border-left: 1px solid #d8d8d8;">
        <!-- 特殊妇女 -->
        <specialWomen v-if="tableName==='特殊妇女'"></specialWomen>
        <specialChildren v-if="tableName==='特殊儿童'"></specialChildren>
        <specialOlds v-if="tableName==='空巢老人'"></specialOlds>
        <specialVeterans v-if="tableName==='退役军人'"></specialVeterans>
      </a-col>
    </a-row>
  </div>
</template>

<script>
import api from "./api";
import specialWomen from './specialWomen/index.vue'
import specialChildren from './specialChildren/index.vue'
import specialOlds from './specialOlds/index.vue'
import SilderMenuList from './silderMenuList.vue';
import specialVeterans from './specialVeterans/index.vue';
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';
const menuListData = ['特殊妇女', '特殊儿童', '空巢老人', '退役军人']
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { SilderMenuList, specialWomen,specialChildren,specialOlds,specialVeterans },
  data() {
    //这里存放数据
    return {
      api,
      menuListData,
      tableName: menuListData[0],
      searchTitle: '特殊人群分类',

    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    changeTableName(item) {
      console.log(item);
      this.tableName = item
    },
   
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {

  },
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {

  },
  beforeCreate() { }, //生命周期 - 创建之前
  beforeMount() { }, //生命周期 - 挂载之前
  beforeUpdate() { }, //生命周期 - 更新之前
  updated() { }, //生命周期 - 更新之后
  beforeDestroy() { }, //生命周期 - 销毁之前
  destroyed() { }, //生命周期 - 销毁完成
  activated() { }, //如果页面有keep-alive缓存功能,这个函数会触发
}
</script>
<style lang='less' scoped>
/deep/.ant-list-item {
  justify-content: flex-start !important;
  margin: 2% 5%;
  background-color: #fff;
  border-radius: 4px;
}
.cursor{
  cursor: pointer;
}
</style>